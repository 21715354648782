import * as React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { Link } from 'gatsby'
import Layout from '../components/_global/Layout'
import { down, up } from 'styled-breakpoints'
import SEO from '../components/_global/SEO'
import { Container } from '../components/shared/Containers'
import { graphql } from 'gatsby'
import * as Popover from '@radix-ui/react-popover'

const Section = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;

  ${down('mdmax')} {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`

const HeroSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 100px;
  z-index: 15;

  ${down('lgmax')} {
    margin: 0 25px;
  }

  ${down('mdmax')} {
    margin: 0;
    padding: 0 1rem;
    flex-direction: column;
    align-items: center;
  }
`

const HeroSectionImage = styled.div`
  max-width: 550px;
  width: 100%;
`

const HeroSectionContent = styled.div`
  padding-left: 40px;

  ${down('mdmax')} {
    padding: 0;
  }
`

const HeroSectionContentIcons = styled.div`
  max-width: 200px;
  margin-bottom: 10px;

  ${down('mdmax')} {
    margin: 0 auto;
  }
`

const HeroSectionContentTitle = styled.h1`
  font-size: 64px;
  line-height: 1.1;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;

  & > span {
    display: block;
    font-size: 24px;
    line-height: inherit;
  }

  ${down('xlmax')} {
    font-size: 36px;

    & > span {
      font-size: 18px;
    }
  }

  ${down('mdmax')} {
    text-align: center;
  }
`

const HeroSectionContentButtons = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 15px;

  ${down('mdmax')} {
    flex-direction: column;
  }
`

const HeroButton = styled.a`
  all: unset;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: uppercase;
  max-width: 500px;
  min-width: 125px;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #7cb227;
  color: #fff;

  ${down('mdmax')} {
    padding: 5px 10px;
  }
`

const HeroButtonRounded = styled(HeroButton)`
  position: relative;
  border-radius: 9999px;
  background-color: #add4e1;
  min-width: 200px;

  & > span {
    width: 51px;
    height: 51px;
    background-color: #fff;
    border-radius: 9999px;
    position: absolute;
    top: 0;
    left: 0;

    ${down('mdmax')} {
      width: 41px;
      height: 41px;
    }

    &:after {
      content: '';
      width: 47px;
      height: 47px;
      border-radius: 9999px;
      background-color: #7cb227;
      position: absolute;
      top: 2px;
      left: 2px;

      ${down('mdmax')} {
        width: 37px;
        height: 37px;
      }
    }
  }
`

const BrownSection = styled(Section)`
  position: relative;
  text-align: center;
  background-color: #b48c51;
  color: #fff;
`

const GreenSection = styled(Section)`
  text-align: center;
  background-color: #7cb227;
  color: #fff;
`

const ContainerSmall = styled.div`
  max-width: 850px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
`

const HeartImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0%, 35%);
`

const StyledLink = styled.a`
  text-decoration: underline;
`

const TextCenter = styled.div`
  text-align: center;
`

const DotWrapper = styled.div`
  position: relative;
`

const FirstImageWrapper = styled.div`
  position: relative;
  max-width: 1000px;
  margin-left: auto;
  margin-right: 5%;

  ${down('mdmax')} {
    margin-right: auto;
  }

  ${up('xl')} {
    margin-bottom: -15%;
    z-index: 10;
  }
`

const SecondImageWrapper = styled.div`
  position: relative;
  max-width: 500px;
  margin-left: 10%;
  margin-right: auto;

  ${down('mdmax')} {
    max-width: 100%;
  }

  ${up('xl')} {
    margin-left: 0;
    max-width: 35%;
  }
`

const ThirdImageWrapper = styled.div`
  position: relative;
  max-width: 1250px;
  margin-left: auto;

  ${down('mdmax')} {
    max-width: 100%;
  }

  ${up('xl')} {
    max-width: 70%;
    margin-top: -30%;
  }
`

const ForthImageWrapper = styled.div`
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
`

const FivethImageWrapper = styled.div`
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const PopupButton = styled.button<{ color: string }>`
  all: unset;
  border-radius: 9999px;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 8px 12px;
  border: 4px solid ${(props) => props.color};
  background-color: #fff;

  ${down('mdmax')} {
    padding: 5px;
    border-width: 2px;
  }

  & > svg {
    width: 30px;
    height: auto;

    ${down('mdmax')} {
      width: 20px;
    }
  }

  & > span {
    ${down('mdmax')} {
      display: none;
    }
  }
`

const StyledPopoverContent = styled(Popover.Content)<{ color: string }>`
  width: 325px;
  background-color: #fff;
  border: 4px solid ${(props) => props.color};
  border-radius: 40px;
  padding: 15px;
`

const PopupButtonFirst = styled(PopupButton)`
  position: absolute;
  top: 18%;
  left: 48%;
`

const PopupButtonSecond = styled(PopupButton)`
  position: absolute;
  top: 22%;
  left: 65%;
`

const PopupButtonThird = styled(PopupButton)`
  position: absolute;
  top: 81%;
  left: 20%;
`

const PopupButtonForth = styled(PopupButton)`
  position: absolute;
  top: 85%;
  left: 75%;
`

const PopupButtonFifth = styled(PopupButton)`
  position: absolute;
  top: 40%;
  left: 40%;
`

const PopupButtonSixth = styled(PopupButton)`
  position: absolute;
  top: 35%;
  left: 38%;
`

const PopupButtonSeventh = styled(PopupButton)`
  position: absolute;
  top: 28%;
  left: 72%;
`

const PopupButtonEight = styled(PopupButton)`
  position: absolute;
  top: 60%;
  left: 60%;
`

const PopupButtonNineth = styled(PopupButton)`
  position: absolute;
  top: 75%;
  left: 70%;
`

const DogYear = ({ data }) => {
  return (
    <Layout>
      <SEO title={'Můj první rok'} lang='cse' />
      <Section>
        <HeroSectionWrapper>
          <HeroSectionImage>
            <Image fluid={data.allFile.nodes[5].childImageSharp.fluid} />
          </HeroSectionImage>
          <HeroSectionContent>
            <HeroSectionContentIcons>
              <Image fluid={data.allFile.nodes[6].childImageSharp.fluid} />
            </HeroSectionContentIcons>
            <HeroSectionContentTitle>
              MŮJ PRVNÍ ROK
              <span>Aneb průvodce pro štěněčí rodiče</span>
            </HeroSectionContentTitle>
            <HeroSectionContentButtons>
              <HeroButton href={'#start'}>Začít</HeroButton>
              <HeroButtonRounded as={Link} to={'/prvni-rok-kocka'}>
                <span />
                Mám kotě
              </HeroButtonRounded>
            </HeroSectionContentButtons>
          </HeroSectionContent>
        </HeroSectionWrapper>
      </Section>
      <BrownSection id={'start'}>
        <Container>
          <h4>Přivezli jste štěňátko</h4>
          <p>
            <strong>
              Máte před sebou malého průvodce do vašich chovatelských začátků,
              který vám pomůže projít s vaším novým čtyřnohým miláčkem jeho
              první měsíce života.
            </strong>
          </p>
          <p>
            Podělíme se s vámi o několik praktických tipů, jak nejlépe o štěně
            pečovat. Všechny rady jsou čerpány z dlouholetých zkušeností řady
            chovatelů. Věříme, že se vám do začátků budou hodit. Doporučujeme,
            abyste se pro další pomoc obrátili na vašeho chovatele, který vám
            dokáže zkušeně poradit
          </p>
          <p>
            Odbornou poradnu najdete také na našem webu:
            <br />
            <StyledLink
              href='https://krmivo-brit.cz/cs/consultations'
              target='_blank'
              rel='noopener noreferrer'
            >
              <strong>Poradna</strong>
            </StyledLink>
          </p>
          <p>
            Přejeme vám i vašemu novému členu rodiny mnoho šťastně prožitých
            společných let.
          </p>
        </Container>
        <HeartImage>
          <svg
            width='440'
            height='609'
            viewBox='0 0 440 609'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M109.058 609C100.932 609 93.33 605.374 87.9563 599.288L-167.622 304.565C-201.83 270.508 -219 225.963 -219 181.677C-219 136.484 -201.044 91.0327 -165.525 56.8469C-130.531 23.0495 -84.6575 6.08612 -39.1776 6.08612C6.95757 6.08612 53.3549 23.438 88.4805 58.0123L106.961 76.1412L133.305 50.7608C168.431 16.9634 214.173 0 259.784 0C305.919 0 352.316 17.3519 387.442 51.9262C424.14 88.0544 440.13 135.966 439.999 182.195C439.999 224.927 426.893 266.883 402.645 300.809L400.024 303.917L351.268 354.419L130.291 599.677C124.917 605.633 117.315 609 109.32 609H109.058ZM-126.205 96.6009C-150.584 120.168 -162.642 150.858 -162.773 181.807C-162.773 212.237 -150.977 242.538 -127.123 266.106L-125.55 267.66L109.844 538.945L309.72 317.125L310.375 316.478L358.083 267.012C374.335 243.704 384.034 212.755 383.903 182.195C383.903 148.139 372.369 115.507 347.86 91.2917C323.613 67.4652 291.895 55.552 259.915 55.552C228.328 55.552 197.003 67.2063 172.887 90.5148L126.621 135.06C115.611 145.678 98.0484 145.549 87.1699 134.801L49.0297 97.3778C24.7825 73.5514 -6.93542 61.6381 -39.0466 61.6381C-70.6334 61.6381 -101.958 73.2924 -126.074 96.4714'
              fill='white'
              fillOpacity='0.15'
            />
          </svg>
        </HeartImage>
      </BrownSection>
      <Section>
        <ContainerSmall>
          <TextCenter>
            <h2>PÁR PRAKTICKÝCH RAD</h2>
            <p>
              Nejenom pravidelný přísun granulí a zásoba hraček stačí vašemu
              štěňátku ke štěstí. Pečovat musíte i o jeho srst, zoubky, oči i
              ušiska.
            </p>
            <p>
              A jak správně začít s venčením nebo kdy se pustit do výcviku?
              Nebojte, všechno potřebné najdete tady. A to jak pro vaše první
              společné měsíce, tak pro spokojený každý den.
            </p>
          </TextCenter>
        </ContainerSmall>
      </Section>
      <section>
        <DotWrapper>
          <FirstImageWrapper>
            <Image fluid={data.allFile.nodes[0].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonFirst color={'#7CB227'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#7CB227'
                    />
                  </svg>
                  <span>Oči</span>
                </PopupButtonFirst>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#7CB227'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Oči</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Kontrolujte, zda oči štěněte nerudnou. Pokud se vyskytne
                    jakýkoliv problém, raději navštivte svého veterinárního
                    lékaře.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonSecond color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Uši</span>
                </PopupButtonSecond>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Uši</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Pravidelně kontrolujte jejich čistotu. Uši čistěte pouze
                    speciálním roztokem pro psy a vatičkou na malíčku -
                    nepoužívejte špejle nebo tyčinky do uší. Až na výjimky
                    postačí čištění 1x za 2 až 3 měsíce. Při komplikacích (např.
                    nepříjemně páchnoucí výtok) je nutné navštívit veterináře.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonThird color={'#7CB227'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#7CB227'
                    />
                  </svg>
                  <span>Česání srsti</span>
                </PopupButtonThird>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#7CB227'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Česání srsti
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Srst česejte pravidelně, 1x až 3x týdně podle náročnosti
                    plemene. V době línání vyčesávejte srst častěji.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonForth color={'#ADD4E1'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#ADD4E1'
                    />
                  </svg>
                  <span>Mytí srsti</span>
                </PopupButtonForth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#ADD4E1'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Mytí srsti</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Pokud se štěně nebo dospívající pejsek zašpiní, umyjte ho ve
                    vaně nebo sprchovém koutě. V případě, že nemáte k dispozici
                    speciální psí kosmetiku, je možné použít také jemný dětský
                    šampon. Od 4. měsíce můžete při procházce zkusit, zda štěně
                    bude mít zájem o koupání v přírodě. Mimo jiné existují i
                    speciální vysoušeče pro psy, kterými je možné srst nejen
                    usušit, ale také vyfoukat od prachu, nečistot a chlupů při
                    línání.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </FirstImageWrapper>
        </DotWrapper>
        <DotWrapper>
          <SecondImageWrapper>
            <Image fluid={data.allFile.nodes[1].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonFifth color={'#B48C51'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#B48C51'
                    />
                  </svg>
                  <span>Hlazení</span>
                </PopupButtonFifth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#B48C51'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Hlazení</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Malá štěňátka zásadně nehladíme směrem od čela přes klenbu
                    hlavy až na temeno - mohlo by dojít k deformaci oušek.
                    Hlaďte je buď od krku dál na hřbet, nebo na náprsence či za
                    oušky směrem od hřbetu.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </SecondImageWrapper>
        </DotWrapper>
        <DotWrapper>
          <ThirdImageWrapper>
            <Image fluid={data.allFile.nodes[2].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonSixth color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Kontrola zoubků</span>
                </PopupButtonSixth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Kontrola zoubků
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Pejska už od štěněcího věku zvykáme na prohlížení zoubků. A
                    to s povelem „ZUBY“.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonSeventh color={'#7CB227'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#7CB227'
                    />
                  </svg>
                  <span>Kontrola varlátek</span>
                </PopupButtonSeventh>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#7CB227'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Kontrola varlátek
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    U psích kluků se také prohlížejí varlata. Pejska postavíte
                    bokem před sebe, lehce podržíte za vzdálenější slabinu,
                    přidržíte mu hlavičku a zezadu mezi nohama sáhnete na
                    varlata. Naučení této prohlídky je přínosem jak u
                    veterináře, tak eventuálně na výstavách, kde zuby a varlata
                    prohlíží rozhodčí.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </ThirdImageWrapper>
        </DotWrapper>
      </section>
      <GreenSection>
        <Container>
          <h2>Výcvik</h2>
          <p>
            <strong>
              Socializace psa začíná v okamžiku, kdy si ho přivedete domů.
            </strong>
          </p>
          <p>
            Hned ho zvykejte na malý látkový nebo kožený obojek. POZOR! Každou
            výuku začínejte krátkou hrou s hračkami, které váš pejsek nemá běžně
            k dispozici a jsou určené pouze ke hře před začátkem výcviku. Takové
            hračky je lepší mít 2 až 3. Dříve, než vůbec začnete s povely, je
            naprosto nezbytné upoutat 100% pozornost štěněte.
          </p>
          <h3>Jak toho dosáhneme?</h3>
          <p>
            <strong>PAMLSKY</strong> – Odměňujte pejska pamlsky společně s
            pozitivní intonací hlasu za vše, co udělá podle vašich představ.
          </p>
          <p>
            Nikdy psa do ničeho násilím nenuťte – to není cesta k ideálnímu
            soužití štěněte a páníčka.
          </p>
          <p>
            <strong>SPOLEČNÁ HRA</strong> – Hru se snažte udělat pro pejska
            zajímavou a zábavnou tak, aby se na ní těšil. Nikdy mu hračky
            nenuťte. Každý pes je ve své podstatě lovec, proto z hračky udělejte
            kořist, kterou musí ulovit.
          </p>
          <p>
            Jako první vždy učte povel přivolání, aby se za vámi pejsek vrátil
            za jakýchkoli okolností. Z počátku jsou štěňata zvyklá na volání,
            kterým je chovatel svolává ke krmení – milým, jemným až zpěvavým
            hlasem. Stejným hlasem je nutné učit i přivolání.
          </p>
        </Container>
      </GreenSection>
      <section>
        <DotWrapper>
          <ForthImageWrapper>
            <Image fluid={data.allFile.nodes[3].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonEight color={'#EEC22A'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#EEC22A'
                    />
                  </svg>
                  <span>Socializace</span>
                </PopupButtonEight>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#EEC22A'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>
                    Socializace
                  </h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Je nutné, aby se štěně seznamovalo s ostatními (jemu cizími)
                    psy i lidmi. Nechte ho pohladit cizími lidmi apod. Následně
                    se může začít s výcvikem. Nejméně do 1/2 až 3/4 roku věku
                    nikdy psa necvičíme v kuse déle než 10 minut. Po tréninku
                    aktivitu na hodinu přerušíme a poté můžeme opět pokračovat.
                    Je to z důvodu, aby pejska výcvik bavil a dělal ho s
                    radostí. Štěně je totiž jako malé dítě a brzy ztrácí
                    koncentraci.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </ForthImageWrapper>
        </DotWrapper>
        <DotWrapper>
          <FivethImageWrapper>
            <Image fluid={data.allFile.nodes[4].childImageSharp.fluid} />
            <Popover.Root>
              <Popover.Trigger asChild>
                <PopupButtonNineth color={'#ADD4E1'}>
                  <svg
                    width='40'
                    height='39'
                    viewBox='0 0 40 39'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.1499 38.9917C16.1199 38.9917 14.2612 38.3571 12.8394 37.2466C11.4261 36.1445 10.424 34.483 10.424 32.596V28.8304H6.56103C4.6167 28.8304 2.91221 27.8619 1.79016 26.4759C0.650973 25.0899 0.00856531 23.2697 0 21.2993V17.7007C0 15.7303 0.650973 13.9101 1.79016 12.5241C2.91221 11.1464 4.6167 10.1696 6.56103 10.1696H10.424V6.40398C10.424 4.50867 11.4176 2.84714 12.8394 1.74502C14.2612 0.634554 16.1285 0.00834939 18.1499 0H21.8415C23.8629 0 25.7302 0.634554 27.152 1.74502C28.5653 2.83879 29.5674 4.50032 29.5674 6.40398V10.1696H33.4304V13.7514H25.8929V6.40398C25.8929 5.75273 25.5674 5.10983 24.8565 4.54207C24.1456 3.98266 23.0664 3.59024 21.8415 3.59024H18.1499C16.9251 3.59024 15.8458 3.98266 15.1349 4.54207C14.4154 5.10983 14.0985 5.75273 14.0985 6.40398V13.7514H6.56103C5.89294 13.7514 5.24198 14.0687 4.65097 14.7617C4.07709 15.4547 3.67452 16.4984 3.67452 17.7007V21.2993C3.67452 22.4933 4.08566 23.5453 4.65097 24.2383C5.23341 24.9396 5.89294 25.2486 6.56103 25.2486H14.0985V32.596C14.0985 33.2473 14.424 33.8902 15.1349 34.4579C15.8458 35.0173 16.9251 35.4181 18.1499 35.4098H21.8415C23.0664 35.4098 24.1456 35.009 24.8565 34.4579C25.576 33.8902 25.8929 33.2473 25.8929 32.596V25.2486H33.4304C34.0985 25.2486 34.758 24.9396 35.3405 24.2383C35.9143 23.5453 36.3255 22.4933 36.3169 21.2993V17.7007C36.3169 16.5067 35.9058 15.4547 35.3405 14.7617C34.758 14.0687 34.0985 13.7514 33.4304 13.7514V10.1696C35.3747 10.1696 37.0792 11.1381 38.2098 12.5241C39.349 13.9101 39.9914 15.7303 40 17.7007V21.2993C40 23.2697 39.349 25.0899 38.2098 26.4843C37.0792 27.8619 35.3833 28.8388 33.4304 28.8388H29.5674V32.6044C29.5674 34.4997 28.5739 36.1612 27.152 37.255C25.7302 38.3654 23.8629 39 21.8415 39H18.1499V38.9917Z'
                      fill='#ADD4E1'
                    />
                  </svg>
                  <span>Venčení</span>
                </PopupButtonNineth>
              </Popover.Trigger>
              <Popover.Portal>
                <StyledPopoverContent sideOffset={10} color={'#ADD4E1'}>
                  <h1 style={{ fontSize: '1.25rem', margin: 0 }}>Venčení</h1>
                  <p
                    style={{
                      fontSize: '1rem',
                      fontWeight: 400,
                      marginTop: '0.25em',
                      marginBottom: 0
                    }}
                  >
                    Když je to možné, snažte se štěňátko venčit bez vodítka, na
                    které ho připínejte pouze v případě nutnosti. Při častém
                    venčení na vodítku je pak těžší štěně přivolat zpátky, když
                    ho pustíte na volno. Trénujte také jeho sledovací pud
                    (štěňata od mala sledují každý krok matky) tím, že se mu
                    schováte. Čím dříve začnete s chůzí bez vodítka, tím bude
                    veškerá práce při výchově a výcviku snadnější.
                  </p>
                </StyledPopoverContent>
              </Popover.Portal>
            </Popover.Root>
          </FivethImageWrapper>
        </DotWrapper>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query imagesDogYear {
    allFile(
      filter: { dir: { regex: "/(year-pes)/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          fixed {
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 2000) {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
            base64
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default DogYear
